import '@gameday/app/lib/js/setPublicPath';
import GamedayMiniAppBase from '@gameday/app/lib/GamedayMiniAppBase';
import initClient from 'bdata-react-component';
import { DataEngine } from '@gameday/core';
import componentMap from './src/componentMap';
import siteConfig from './src/js/site-config-options';
import '@gameday/app/lib/styles/app.css';
import './lib/styles/app.css';
import { dealWithAdobeMc } from '@gameday/tracking/lib/adobe-tracking';

class GamedayMiniApp extends GamedayMiniAppBase {
    constructor (cfg) {
        super(cfg || {
            ...siteConfig.game,
            config: siteConfig.config,
            tracking: siteConfig.tracking,
            mount: siteConfig.mount
        });
        const moreOpts = {
            componentMap,
            DataEngine
        };
        dealWithAdobeMc();
        const opts = { ...this.opts, ...moreOpts };
        const { componentId } = opts?.config;

        if (componentId === 'strikezone') {
            document.body.classList.add('sz-only');
        }
        const _root = initClient(this.appContainer, opts);

        if (opts?.timecode) {
            this.hashListener.dispatchTimecode(opts?.timecode);
            this.init(_root);
        } else {
            this.init(_root);
        };
    }
};

export const bootstrap = function (ops) {
    const { gamePk, componentId, mountId, useFullViewport, timecode, teamId, themeMode, tracking, timezone } = ops;

    if (tracking) {
        siteConfig.tracking = {
            ...siteConfig.tracking,
            ...Object.keys(tracking).reduce((acc, key) => {
                acc[key] = { ...siteConfig.tracking[key], ...tracking[key] };
                return acc;
            }, {})
        };
    }

    const { mount, game } = siteConfig;
    const gameConfig = { ...game, game_pk: gamePk, game: gamePk };
    const cfg = { ...gameConfig, config: { ...siteConfig.config, componentId, useFullViewport, teamId, themeMode, timezone }, mount: mountId || mount, tracking: siteConfig.tracking, timecode };

    const app = new GamedayMiniApp(cfg);
    app.prefetch().then(() => {
        app.render();
    }).then(() => {
        console.log('Gameday Mini Rendered');
    });
};

export default GamedayMiniApp;
