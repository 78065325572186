import hashParams from '@gameday/app/lib/store/hashParams';
import dataRouter from '@gameday/app/lib/store/DataRouterMiniHome';
import getAdobeAppConfig from '@gameday/utils/getAdobeAppConfig';
const supportedLanguages = new Set(['en', 'es', 'ja']);
const getSiteConfig = () => {
    let appParams;
    let language;
    let timecode;
    let themeMode;
    const appInfoSource = document.getElementById('appInfo');
    if (appInfoSource) {
        appParams = (appInfoSource && JSON.parse(appInfoSource.innerText)) || {};
    }
    const params = new URLSearchParams(location.search);
    const env = window?.dataEnv || appParams?.env || 'prod';
    const deviceLib = require('device/device');
    const deviceBootstrap = deviceLib();
    const { rsid, adobeMc } = getAdobeAppConfig();
    const iosRSID = env === 'prod' ? 'mlbios.at.bat.new.implementation' : 'mlbdev.at.bat.ios.new';
    const androidRSID = env === 'prod' ? 'mlbat.bat.android' : 'mlbdev.at.bat.android.new';
    const desktopRSID = env === 'prod' ? 'mlbglobal08, mlbcom08' : 'devmlbglobal, devmlbcom';
    let readHash = true;

    // Check if we are on a VSM context
    // Current iOS Safari UA checks (and a match against desktop safari) are required for MLBAPP iPad webviews on the home surface.
    // Until the webvieew UA is adjusted and does not force desktop mode, we'll need to differentiate
    // between the two contexts.
    // Lets confirm we're on a VSM context (club homepages).  If so, we need to force desktop mode.
    // to avoid attempting deep links into the mlb app.
    const isVsmContext = document.querySelector('meta[name="page_section"]')?.content === 'Homepage';

    const getAppDeviceString = () => {
        if (!deviceBootstrap || isVsmContext) {
            return 'desktop';
        }

        if (deviceBootstrap.is.ios) {
            return 'ios';
        }

        if (deviceBootstrap.is.android) {
            return 'android';
        }

        return 'desktop';
    };
    const DEVICESTRING = getAppDeviceString();

    let rsidFallback;
    if (DEVICESTRING === 'android') {
        rsidFallback = androidRSID;
    } else if (DEVICESTRING === 'ios') {
        rsidFallback = iosRSID;
    } else {
        rsidFallback = desktopRSID;
    }

    if (appInfoSource) {
        readHash = false; // If appInfoSource is present, we are on a web surface
        // appParams from appInfo script tag
        timecode = params.get('tfs') || appParams?.timecode;
        language = appParams?.lang;
    } else {
        appParams = hashParams(); // appParams from hash for standalone app
        timecode = appParams?.timecode;
        language = appParams?.lang || 'en';
        if (appParams?.themeMode) {
            themeMode = appParams?.themeMode;
        }
    }
    if (!supportedLanguages.has(language)) { // if we're asking for an unsupported language, default to en
        language = 'en';
    }
    const gamePk = appParams?.game_pk || appParams?.game || '';
    const properties = {
        enableSW: true,
        mount: '#gameday-index-component__root',
        game: {
            language,
            game: gamePk,
            game_state: appParams?.game_state || '',
            game_tab: appParams?.game_tab || '',
            dataMode: timecode ? 'passive' : 'gumbo',
            game_pk: gamePk,
            logging: 'none',
            debug: true,
            scoreboard: {
                preferMLB: true
            },
            basename: '/',
            detail: appParams?.detail || '',
            server: false,
            // explicitly set to true since we're a guest on home surfaces
            // on web or app.
            useMemoryHistory: true
        },
        config: {
            env,
            logging: 'log',
            adRefreshRate: 60,
            appView: DEVICESTRING,
            language,
            siteProperty: 'mlb',
            dictionary: {},
            siteLabel: 'MLB',
            seoSitePrefix: 'mlb',
            enableFieldVision: false,
            enableFieldVisionOD: false,
            forceFieldVisionOn: false,
            videoAdsVolume: '10',
            videoAdsMute: true,
            showVideoAds: true,
            batEnabled: true,
            forceDFV: false,
            appPath: 'app/atbat/',
            localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            disableWebSockets: true,
            webSocketWindow: 18,
            nativeVideoPlayer: false,
            enableWebEntitlement: false,
            componentId: appParams?.id || '',
            fetchDataOnce: !!timecode,
            dataRouter,
            isStickyScore: appParams?.isStickyScore === 'true',
            hashState: {
                read: readHash,
                write: false, // we're never writing for home
                watch: readHash
            }
        },
        sponsor: '',
        tracking: {
            suite: {
                desktop: rsid || rsidFallback,
                mobile: rsid || rsidFallback
            },
            props: {
                pageName: 'Major League Baseball: Gameday Mini',
                appName: 'Gameday Mini',
                channel: 'Gameday',
                prop1: 'Gameday Initial Load',
                currencyCode: 'USD',
                omnitureSetting: {
                    eVar73: 'mlbglobal08,mlbcom08'
                }
            }
        },
        skin: {},
        rules: {}
    };

    const appOverride = params.get('appOverride');
    const enableFieldVisionOverride = params.get('enableFieldVision');
    const enableFieldVisionODOverride = params.get('enableFieldVisionOD');
    const forceFieldVisionOn = params.get('forceFV') === 'true';
    const forceWebSockets = false;
    const forceDFV = params.get('forceDFV') === 'true';
    const stitchEnv = params.get('stitch_env');

    window.AppAdobeTrackingConfig = { ...properties.tracking, ...{ locale: language, adobeMc } };

    if (timecode) {
        properties.game.timecode = timecode;
    }

    if (appOverride !== null) {
        properties.config.appOverride = appOverride;
    }

    if (enableFieldVisionOverride !== null) {
        properties.config.enableFieldVision = enableFieldVisionOverride === 'true';
    }

    if (enableFieldVisionODOverride !== null) {
        properties.config.enableFieldVisionOD = enableFieldVisionODOverride === 'true';
    }

    if (stitchEnv !== null) {
        properties.config.stitchEnv = stitchEnv;
    }

    properties.config.forceFieldVisionOn = forceFieldVisionOn;
    properties.config.forceWebSockets = forceWebSockets;
    properties.config.forceDFV = forceDFV;

    if (themeMode) {
        properties.config.themeMode = themeMode;
        const root = document.getElementsByTagName('html')[0];
        if (root) {
            root.classList.add(themeMode);
        }
    }

    return properties;
};

export default getSiteConfig();
