import React from 'react';
import MLBGamedayTrackingEngine from '@gameday/tracking/MLBGamedayTrackingEngine';
const Preview = React.lazy(() => import(/* webpackChunkName: "preview" */ '@gameday/components/Preview/containers/PreviewMiniHomeDataContainer'));
const Live = React.lazy(() => import(/* webpackChunkName: "live" */ '@gameday/components/Live/containers/LiveMiniHomeDataContainer'));
const Final = React.lazy(() => import(/* webpackChunkName: "final" */ '@gameday/components/Final/containers/FinalMiniHomeDataContainer'));

export default {
    preview: Preview,
    live: Live,
    final: Final,
    strikezone: Live,
    tracking: MLBGamedayTrackingEngine
};
